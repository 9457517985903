import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';

const Tramites = () => {
  useEffect(() => {
    const redirect = () => {
      if (!window || typeof window === 'undefined') {
        navigate('/');
        return;
      }

      window.location.href = 'https://tramites.isset.gob.mx:6001/tramites/login';
    }
    redirect();
  }, [])

  return (
    <div>
      <Typography>Redireccionando al portal de trámites, por favor espere.</Typography>
    </div>
  );
};

export default Tramites;